<mat-toolbar [class.z-50]="isOpened()">
  <div
    class="navigation">
    <div class="nav-container">
      <div class="nav-logo">
        <a [routerLink]="'/'" aria-label="Strona główna - Poetica.pl">
          <app-logo></app-logo>
          <span class="logo-text">Poetica.pl</span>
        </a>
      </div>

      <div class="menu-items">
        <ul>
          @for (item of menuItems; track item) {
          <li>
            <div>
              <a [routerLink]="item.link">
                {{  item.label }}
              </a>
            </div>
          </li>
          }
        </ul>
      </div>

      <div
        class="nav-actions">
        <app-toggle-dark-mode></app-toggle-dark-mode>

        <div class="toggler">
          <button aria-label="Otwórz menu" (click)="toggleSidenav()">
            <mat-icon [svgIcon]="'mat_outline:menu'"></mat-icon>
          </button>
        </div>

        @if (isLoggedIn()) {
          <app-user-menu></app-user-menu>
        } @else {
          <a [routerLink]="'/logowanie'" class="login-button" aria-label="Zaloguj">
            Zaloguj
          </a>
        }
        <!--        <SearchModal />-->
        <!--        <NotifyDropdown />-->
        <!--        <AvatarDropdown />-->
      </div>
    </div>
  </div>
</mat-toolbar>
