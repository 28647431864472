import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FieldTypeConfig, FormlyModule} from '@ngx-formly/core';
import {FieldType} from '@ngx-formly/material';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ContentEditableValueAccessor} from "../contenteditable/content-editable-value-accessor.directive";

@Component({
  selector: 'app-title-field',
  templateUrl: './title-field.component.html',
  styleUrls: ['./title-field.component.scss'],
  standalone: true,
  imports: [
    FormlyModule, ReactiveFormsModule, FormsModule, ContentEditableValueAccessor
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleFieldComponent extends FieldType<FieldTypeConfig> {


}
