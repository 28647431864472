import {AbstractControl} from '@angular/forms';
import {FormlyFieldConfig} from "@ngx-formly/core";

export class ValidationService {

  // @ts-ignore unused variable
  static emailValidator(control: AbstractControl, field: FormlyFieldConfig) {
    // RFC 2822 compliant regex
    if (control.value && control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return { email: true };
    }
  }
}

export class ValidationMessages {

  // @ts-ignore unused variable
  static contentMinLengthValidationMessage(err: any, field: FormlyFieldConfig) {
    return `Wprowadzono zbyt małą ilość znaków ${err.current}/${err.min}`
  }

  // @ts-ignore unused variable
  static contentMaxLengthValidationMessage(err: any, field: FormlyFieldConfig) {
    return `Wprowadzono zbyt dużą ilość znaków ${err.current}/${err.max}`
  }

  // @ts-ignore unused variable
  static minLengthValidationMessage(err: any, field: FormlyFieldConfig) {
    const minLength = `${field.props?.minLength}`;
    const currentLength = field.formControl?.value.length;

    if (parseInt(minLength, undefined) === 1) {
      return "Pole powinno składać się z minimum 1 znaku"
    } else {
      return `Wprowadzono zbyt małą ilość znaków ${currentLength}/${minLength}`;
    }
  }

  // @ts-ignore unused variable
  static other(err: any, field: FormlyFieldConfig) {
    return err.message;
  }

  // @ts-ignore unused variable
  static invalidEmailMessage(err: any, field: FormlyFieldConfig) {
    return "Nieprawidłowy adres e-mail"
  }

  // @ts-ignore unused variable
  static maxLengthValidationMessage(err: any, field: FormlyFieldConfig) {
    const maxLength = `${field.props?.maxLength}`;

    const currentLength = field.formControl?.value.length;

    if (parseInt(maxLength, undefined) === 1) {
      return "Pole powinno składać się z maksymalnie 1 znaku"
    } else {
      return `Wprowadzono zbyt dużą ilość znaków ${currentLength}/${maxLength}`;
    }
  }

  // @ts-ignore unused variable
  static minValidationMessage(err: any, field: FormlyFieldConfig) {
    return `Wartość nie powinna być mniejsza niż: ${field.props?.min}`;
  }

  // @ts-ignore unused variable
  static maxValidationMessage(err: any, field: FormlyFieldConfig) {
    return `Wartość nie powinna przekraczać:  ${field.props?.max}`;
  }

  // @ts-ignore unused variable
  static patternValidationMessage(err: any, field: FormlyFieldConfig) {
    return `Wprowadzony tekst nie pasuje do wzorca: ${field.props?.pattern}`;
  }

  // @ts-ignore unused variable
  static fieldMatch(err: any, field: FormlyFieldConfig) {
    return `Wprowadzony tekst nie zgadza się z drugim polem`
  }
}
