import { Injectable, Inject } from '@angular/core';
import {ReplaySubject, Observable, firstValueFrom} from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ScriptLoaderService {
  private scripts = [
    "https://umami.pospiech.dev/script.js",
    "/assets/js/cropper.js",
  ];

  loader: { [url: string]: ReplaySubject<void> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  loadScript(url: string): Observable<void> {
    if (this.loader[url]) {
      return this.loader[url].asObservable();
    }

    this.loader[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = () => {
      this.loader[url].next();
      this.loader[url].complete();
    };

    this.document.body.appendChild(script);

    return this.loader[url].asObservable();
  }

  async loadScripts() {
    return Promise.all(
      this.scripts.map((script) => firstValueFrom(this.loadScript(script)))
    );
  }
}
